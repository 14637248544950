<script setup lang="ts">
</script>

<template>
  <catalog-image></catalog-image>
  <section class="catalog">
    <common-page-header></common-page-header>
    <section class="catalog-container flex-col">
      <div class="catalog-header flex-col">
        <div class="flex-c-b">
          <h1>{{ $t('components.catalog.header.catalog') }}</h1>
        </div>

        <common-banner :title="$t('components.catalog.header.bestPlantations')">
          {{ $t('components.catalog.header.selectSuppliers') }}
          <br/>
          {{ $t('components.catalog.header.youCouldCount') }}
        </common-banner>
      </div>

      <slot></slot>
    </section>

    <catalog-footer></catalog-footer>
  </section>
</template>

<style lang="scss" scoped>
.catalog {
  @apply padding-top-for-fixed-header;

  &-container {
    @apply px-4 md:px-[5%] py-8 gap-8;
  }
}
</style>
